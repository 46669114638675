import React from 'react';


const TutorialSection = () => {
  return (
    <div className="container">
      <h2 className='text-white text-center'>Video tutorial</h2>
      <div className='row mt-5'>
        
        <div className="col-md-6">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim vel illum dolore eu feugiat nulla facilisis at vero eros.
          </p>
        </div>
        <div className="video-tutorial col-md-6">
          <p>video tutorial mini</p>
        </div>
      </div>
    </div>
  );
};

export default TutorialSection;
