import {

    GET_DETAIL_SUCCESS,
    GET_DETAIL_FAIL,
    GET_FAVORITES_FAIL,
    GET_FAVORITES_SUCCESS,
    CLEAR_DETAIL,

} from '../actions/restaurants/types'


const initialState = {
    detail: null,

}

export default function auth(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_DETAIL_SUCCESS:
            return {
                ...state,
                detail: payload,

            }
        case GET_DETAIL_FAIL:
            return {
                ...state,
                detail: null,

            }
        case CLEAR_DETAIL:
            return {
                ...state,
                detail: null
            };
        case GET_FAVORITES_SUCCESS:
            return {
                ...state,
                favorites: payload
            };
        case GET_FAVORITES_FAIL:
            return {
                ...state,
                favorites: null
            };

        default:
            return state
    }
}