
import Layout from "hocs/layouts/Layout"
import { connect } from "react-redux";
import Navbar from "components/navigation/Navbar";
import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import restaurant from "assets/img/burguer contact.jpg";
import Footer from "components/navigation/Footer";

function Contact({

}) {

    const [formData, setFormData] = useState({
        fullName: '',
        reason: '',
        email: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        // Aquí puedes agregar la lógica para enviar los datos
    };





    return (
        <Layout>
            <Navbar />


            <div className="container-flex-head-2 p-5">
                <div className="container  " style={{ backgroundColor: '#05060c', borderRadius: '10px' }}>
                    <div className="row ">
                        <div className="col-md-4">

                            <img src={restaurant} style={{  borderRadius: '10px' }} className="img-fluid" alt="" />

                        </div>
                        <div className="col-md-8 p-4">
                            <h2 className="text-white text-center mb-4">¿Necesitas contactarnos?</h2>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="formFullName">
                                            <Form.Label className="text-white">Nombre completo</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digita tu Nombre completo"
                                                name="Digita tu Nombre completo "
                                                value={formData.fullName}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="formReason">
                                            <Form.Label className="text-white">Razón del contacto</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="reason"
                                                value={formData.reason}
                                                onChange={handleInputChange}
                                            >
                                                <option>Selecciona una opción</option>
                                                <option>Soporte</option>
                                                <option>No aparece mi restaurante</option>
                                                <option>Otro</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="formEmail">
                                            <Form.Label className="text-white">Email de contacto</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="ejemplo@email.com"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="formMessage">
                                            <Form.Label className="text-white">Mensaje</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Digita tu mensaje"
                                                name="message"
                                                value={formData.message}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12} className="text-center ">
                                        <button type="submit" className="cssbuttons-io-button mx-auto p-3 ">
                                            Envíar Mensaje
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>

            </div>

            <Footer className="mt-5"></Footer>



        </Layout>
    )
}
const mapStateToProps = state => ({






})

export default connect(mapStateToProps, {


})(Contact)