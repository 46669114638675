
import Layout from "hocs/layouts/Layout"

import { useEffect } from 'react'
import { connect } from "react-redux";
import React, { useState } from 'react';
import Navbar from "components/navigation/Navbar";
import { register } from "redux/actions/auth/auth";
import { Navigate } from "react-router-dom";
import Footer from "components/navigation/Footer";
function Register({

    register
}) {


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [accountCreated, setAccountCreated] = useState(false);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        re_password: ''
    })

    const {
        first_name,
        last_name,
        email,
        password,
        re_password
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {

        console.log(formData)
        e.preventDefault();
        register(first_name, last_name, email, password, re_password);
        setAccountCreated(true);
        window.scrollTo(0, 0)
    }

    return (
        <Layout>
            <Navbar />

            <div class="container-log mx-auto  my-5">
                <div class="login-form mx-auto my-5">
                    <div class="header">
                        <label class="title">Registrar</label>
                        <p class="description mt-5">Registrate en Eazt ingresando los siguientes datos.</p>
                    </div>
                    <form onSubmit={e => { onSubmit(e) }} className="form_main mx-auto  " action="">

                        <div className="container">
                            <div className="row">

                                <div className="col-md-12">
                                    <div class="input_container my-1 ">

                                        <input placeholder="Nombre"
                                            id="first_name" name='first_name'
                                            className="input_field"
                                            type="text"
                                            required
                                            value={first_name}
                                            onChange={e => onChange(e)} />
                                    </div>
                                </div>

                                <div className="col-md-12">

                                    <div class="input_container my-1 ">

                                        <input placeholder="Apellido"
                                            id="last_name" name='last_name'
                                            className="input_field"
                                            type="last_name"
                                            required
                                            value={last_name}
                                            onChange={e => onChange(e)} />
                                    </div>
                                </div>
                                <div className="col-md-12">

                                    <div class="input_container my-1 ">

                                        <input placeholder="Email"
                                            id="email" name='email'
                                            className="input_field"
                                            type="email"
                                            required
                                            value={email}
                                            onChange={e => onChange(e)} />
                                    </div>
                                </div>

                                <div class="input_container my-1 col-md-12">
                        
                                    <input placeholder="Password"
                                        id="password"
                                        className="input_field "
                                        type="password"
                                        name='password'
                                        value={password} required
                                        onChange={e => onChange(e)} />
                                </div>
                                <div class="input_container my-1 col-md-12">
             
                                    <input placeholder="re_password"
                                        id="re_password"
                                        className="input_field "
                                        type="password"
                                        name='re_password'
                                        value={re_password} required
                                        onChange={e => onChange(e)} />
                                </div>

                            </div>
                        </div>
                        <button class="sign-in_btn" type="submit" title="Sign In">
                            <span>Registrarme</span>
                        </button>

                    </form>
                </div>

            </div>
            <Footer></Footer>


        </Layout>
    )
}
const mapStateToProps = state => ({


})
export default connect(mapStateToProps, {

    register

})(Register)