import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import eazt from "assets/img/logo largo.png";
import eaztBrand from "assets/img/eazt logo.png";
import { useState, useEffect } from "react";
const CustomNavbar = () => {

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    function handleResize() {
        setIsSmallScreen(window.innerWidth < 768);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
}, []);
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="custom-navbar py-3">
        <Container>
          {/* Botón hamburguesa */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="text-nav" style={{ color: 'white' }}  />

          {/* Logo a la izquierda */}
          <a href='/'>
            <img
              src={eaztBrand}
              width="60px"
              className="d-inline-block align-top img-fluid"
              alt="Eazt Logo"
            />
          </a>

          {/* Contenido colapsable */}
          <Navbar.Collapse id="responsive-navbar-nav ">
            {/* Menú centrado */}
            <Nav className="mx-auto text-center d-flex align-items-center justify-content-center">
              <Nav.Link href="/about" className="text-nav" style={{ marginLeft: isSmallScreen ? "0px" : "80px" }}>Nosotros</Nav.Link>

              {/* Logo centrado */}
              <Navbar.Brand href="/" className="mx-4">
                <img
                  src={eazt}
                  width="100px"
                  className="d-inline-block align-top img-fluid "     
                  alt="Eazt Logo"
                />
              </Navbar.Brand>

              <Nav.Link href="/contact" className="text-nav">Contacto</Nav.Link>
            </Nav>

            {/* Botón a la derecha */}
            <Nav className="ml-auto d-flex justify-content-end align-items-center">

            <a href='/login' style={{textDecoration:"none"}}>
              <button class="cssbuttons-io-button mx-auto px-3 py-2">

                
                  Empezar App
              
              </button>
              </a>


            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  );
};

export default CustomNavbar;
