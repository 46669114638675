
import { useParams } from 'react-router'
import { useState } from 'react'
import { connect } from 'react-redux'
import { activate } from "redux/actions/auth/auth"
import Layout from "hocs/layouts/Layout"
import { Navigate } from 'react-router'

import Navbar from 'components/navigation/Navbar'
import Footer from 'components/navigation/Footer'
const Activate = ({
    activate,


}) => {
    const params = useParams()
    const [activated, setActivated] = useState(false);

    const activate_account = () => {
        const uid = params.uid
        const token = params.token
        activate(uid, token);
        setActivated(true);
    }

    if (activated)
        return <Navigate to='/login' />;

    return (
        <Layout>

            <Navbar />
            <div className="container-log mx-auto  my-5">
                {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
                <div className="login-form  mx-auto my-5">

                <div class="header">
                        <label class="title">¡Bienvenido!</label>
                        <p class="description mt-5">Para finalizar debes activar tu cuenta clickeando el siguiente botón</p>
                    </div>

                    


                    <button
                        onClick={activate_account}
                        className="cssbuttons-io-button mx-auto"
                    >
                        Activar Cuenta
                    </button>

                </div>
            </div>

            <Footer />
        </Layout>
    )
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {
    activate
})(Activate)