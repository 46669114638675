
import Layout from "hocs/layouts/Layout"
import { connect } from "react-redux";
import React, { useState } from 'react';
import CardDate from "components/home/CardDate";
import NavbarApp from "components/navigation/NavbarApp";
import { useEffect } from 'react'
import { Navigate } from "react-router-dom";
import { check_authenticated, load_user, login, refresh } from "redux/actions/auth/auth";
import Footer from "components/navigation/Footer";
import CardPricing from "components/pricing/CardPricing";
import CustomNavbar from "components/navigation/Navbar";
function Favorites({


}) {
  useEffect(() => {
    window.scrollTo(0, 0)

  }

    , [])








  return (
    <Layout>

      <CustomNavbar></CustomNavbar>

      <div className="container">
        <h1 className="h1-title text-center my-4" >Nuestros Planes</h1>
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center align-items-center ">
          <CardPricing  
          plan="Starter" 
          price={"249$"}
          oneItem={"Mejora la visibilidad en Eazt."}
          twoItem={"Web Page no será requerida."}
          threeItem={"Foto del negocio personalizada."}
          className=" mx-auto">

          </CardPricing>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center ">
          <CardPricing  
          plan="Expert" 
          price={"299$"}
          oneItem={"Beneficios Plan Starter"}
          twoItem={"Obtén el verificado de Eazt."}
          threeItem={"El Rating no será requerido."}
          className=" mx-auto">

          </CardPricing>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center ">
          <CardPricing  
          plan="Gold" 
          price={"369$"}
          oneItem={"Beneficios Plan Expert"}
          twoItem={"IA personalizada."}
          threeItem={"Adjunta carta y detalles del negocio."}
          className=" mx-auto">

          </CardPricing>
          </div>


        </div>




      </div>

      <Footer></Footer>

    </Layout>
  )
}
const mapStateToProps = state => ({



})

export default connect(mapStateToProps, {



})(Favorites)