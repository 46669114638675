import { connect } from "react-redux"


function Layout({ children }) {

    return (
 



          

            <div style={{ position: "relative", zIndex: 2 }}>

                {children}
            </div>


    )

}

const mapStateToProp = state => ({ //llama las variables de redux que quiero llamar


})
export default connect(mapStateToProp, {



})(Layout)