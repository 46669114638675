import { connect } from "react-redux"
import { useState } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import CardChat from "components/restaurants/CardChat";
import { StarFill, GeoAltFill } from "react-bootstrap-icons";
import { motion } from "framer-motion";
import flecha from "assets/img/flechaDerecha.png";
import flechaN from "assets/img/flechaDerechaNegra.png";
function CardRestaurants({ name, types, photoUrl, rating, reviews, distance, resume }) {
    const [showModal, setShowModal] = useState(false);


    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
    return (
        <>
            <motion.div initial={{ y: -70, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} viewport={{ once: true }}  >
                <article onClick={openModal} className="article-wrapper my-3 mx-auto"              onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>
                    <div className="rounded-lg container-project">
                        <img src={photoUrl} alt={name} style={{ width: '100%', height: '200px', objectFit: "cover", borderRadius: "10px" }} />
                    </div>
                    <div className="project-info">
                        <div className="flex-pr">
                            <div
                                className="project-hover mt-4"
                   
                            >
                                <img src={isHovered ? flecha : flechaN} className="img-fluid" alt="Flecha" />
                            </div>
                            <div className="project-title text-nowrap ms-2 mt-4">{name}</div>

                        </div>
                        <div className="direction ">

                            <p><GeoAltFill className="mx-1" color="#db0000"></GeoAltFill>{distance} km</p>
                        </div>
                        <div className="rating">

                            <p className="text-black"><StarFill color="#e7da0a"></StarFill> {rating}</p>

                        </div>
                    </div>
                </article>
                <CardChat showModal={showModal} closeModal={closeModal} name={name} rating={rating} reviews={reviews} resume={resume} photoUrl={photoUrl} />

            </motion.div>

        </>

    );
}

const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(CardRestaurants)