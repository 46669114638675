import { connect } from "react-redux"
import { useState } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from "react-bootstrap";
import { get_opinion, clearDetail } from "redux/actions/restaurants/restaurants";
import { useEffect } from "react";
import { Typewriter } from "react-simple-typewriter"
import eaztBrand from "assets/img/eazt logo.png";
import axios from "axios";
import { ShareFill, HeartFill, Whatsapp, PlusLg } from "react-bootstrap-icons";
function CardChat({
    showModal,
    closeModal,
    get_opinion,
    name,
    detail,
    resume,
    rating,
    reviews,
    clearDetail,
    photoUrl





}) {
    const [isFinished, setIsFinished] = useState(false);
    const [conclusion, setConclusion] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        rating: '',
        reviews: '',
        photoUrl:''

    })


    const getCSRFToken = () => {
        const name = 'csrftoken';
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return '';
    };

    const addFavorite = () => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'X-CSRFToken': getCSRFToken(),
                'Authorization': `JWT ${localStorage.getItem('access')}`
            },
        };
    
        const form = new FormData();
        form.append('name', name);
        form.append('rating', rating);
        form.append('reviews', reviews);
        form.append('photoUrl', photoUrl);
    
        const fetchForm = async () => {
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/restaurants/addFavorite`,
                    form,
                    config
                );
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error.response && error.response.status === 404) {
                    alert('Error al enviar datos');
                } else {
                    console.error(error);
                }
            }
        };
    
        fetchForm();
    };
    






    useEffect(() => {
        if (showModal) {
            const fetchOpinion = async () => {

                await get_opinion(name, rating, reviews, resume); // Obtén la respuesta completa



            }
            fetchOpinion(); // Ejecuta la función de manera asíncrona
        }
    }, [showModal, get_opinion, name]); // Se ejecuta cuando showModal cambia
    useEffect(() => {
        if (detail) {
            // Convertir a minúsculas y quitar tildes
            const normalizedDetail = detail.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            // Extraer el texto después de "conclusión:"
            const conclusionMatch = normalizedDetail.match(/conclusión.\s*(.*)/);
            if (conclusionMatch) {
                setConclusion(conclusionMatch[1].trim());
            }
        }
    }, [detail]); // Dependencia en detail

    const handleCloseModal = () => {
        setConclusion('');   // Reiniciar la conclusión
        setIsFinished(false); // Reiniciar el estado de escritura
        closeModal();         // Cerrar el modal
        clearDetail();        // Limpia el estado de detail (Redux)
    };

    if (!showModal) {
        return null;
    }


    return (
        <>

            <div className={`modal-overlay ${showModal ? "show" : ""}`} onClick={handleCloseModal}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="card_chat px-5">


                        <div className="body">
                            <img src={eaztBrand} className="mt-5" width={100}></img>




                            {detail ? (

                                <p className="mt-2 text-white text-justify">
                                    {conclusion && <h3 className="text-white">{conclusion}</h3>} {/* Mostrar conclusión */}

                                    {!isFinished ? (
                                        <Typewriter
                                            words={[detail]}
                                            cursor
                                            cursorStyle='_'
                                            typeSpeed={1}  // Velocidad de escritura
                                            delaySpeed={1500} // Pausa entre palabras
                                            onDone={() => setIsFinished(true)} // Establecer el estado a true al finalizar
                                        />
                                    ) : (
                                        { detail } // Mostrar el texto completo
                                    )}

                                </p>




                            ) : (
                                <p className="text-white mt-5">Cargando opinión...</p>
                            )}
                            <div className="icons mt-2">



                                <button class="btn-chat" onClick={addFavorite}>
                                    <HeartFill className="icon"></HeartFill>
                                </button>
                                <button class="btn-chat">
                                    <Whatsapp className="icon"></Whatsapp>
                                </button>
                                <button class="btn-chat">
                                    <PlusLg className="icon"></PlusLg>
                                </button>

                            </div>

                        </div>







                    </div>
                </div>
            </div>


        </>

    );
}

const mapStateToProps = state => ({
    detail: state.restaurants.detail ? state.restaurants.detail.response : null,


})
export default connect(mapStateToProps, {
    get_opinion,
    clearDetail,

})(CardChat)