import { connect } from "react-redux"


import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from "react-bootstrap";

function PricingCard({
    plan,
    price,
    oneItem,
    twoItem,
    threeItem


}) {


    return (
        <>


            <div class="card-pricing">
                <div class="header">
                    <span class="title">{plan}</span>
                    <span class="price">{price}</span>
                </div>
                <p class="desc">Etiam ac convallis enim, eget euismod dolor.</p>
                <ul class="lists">
                    <li class="list">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                        </svg>
                        <span>{oneItem}</span>
                    </li>
                    <li class="list">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                        </svg>
                        <span>{twoItem}</span>
                    </li>
                    <li class="list">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                        </svg>
                        <span>{threeItem}</span>
                    </li>
                </ul>
                <button type="button" class="action">Get Started</button>
            </div>


        </>

    );
}

const mapStateToProps = state => ({


})
export default connect(mapStateToProps, {


})(PricingCard)