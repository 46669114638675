
import Layout from "hocs/layouts/Layout"
import { connect } from "react-redux";
import React, { useState } from 'react';
import CardDate from "components/home/CardDate";
import NavbarApp from "components/navigation/NavbarApp";
import axios from "axios";
import { useEffect } from 'react'
import { Navigate } from "react-router-dom";
import { check_authenticated, load_user, login, refresh } from "redux/actions/auth/auth";
import PacmanLoader from "react-spinners/PacmanLoader"
import { Typewriter } from 'react-simple-typewriter';
import Footer from "components/navigation/Footer";
import eaztBrand from "assets/img/eazt logo.png";

function Restaurants({
  login,
  isAuthenticated,
  refresh,
  check_authenticated,
  load_user,
  user

}) {
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [showRestaurants, setShowRestaurants] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [formData, setFormData] = useState({
    longitude: '',
    latitude: '',
  });


  useEffect(() => {
    window.scrollTo(0, 0)

    isAuthenticated ? <> </> :
      <>
        {refresh()}
        {check_authenticated()}
        {load_user()}
      </>
  }, [])
  if (isAuthenticated == false) {
    return <Navigate to='/ingresar' />
  }



  const sendLocationToBackend = (latitude, longitude) => {

    const config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',

        'Authorization': `JWT ${localStorage.getItem('access')}`
      },
    };

    const formData = new FormData();
    formData.append('longitude', longitude);
    formData.append('latitude', latitude);

    const fetchData = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/restaurants/`,
          formData,
          config
        );
        setRestaurants(res.data);
        console.log(res.data)
        setLoading(false)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          alert('Error al enviar datos');
        } else {
          console.error(error);
        }
      }
    };

    fetchData();
  };

  const getLocation = () => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`Latitud: ${latitude}, Longitud: ${longitude}`);
          setLatitude(parseFloat(latitude))
          setLongitude(parseFloat(longitude))


          // Enviar ubicación al backend
          setLoading(true);
          sendLocationToBackend(latitude, longitude);

          setShowRestaurants(true);





        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setLocation('El usuario denegó la solicitud de geolocalización.');
              break;
            case error.POSITION_UNAVAILABLE:
              setLocation('La información de ubicación no está disponible.');
              break;
            case error.TIMEOUT:
              setLocation('La solicitud para obtener la ubicación expiró.');
              break;
            case error.UNKNOWN_ERROR:
              setLocation('Se produjo un error desconocido al obtener la ubicación.');
              break;
            default:
              setLocation('Error al obtener la ubicación.');
          }
        }
      );
    } else {
      setLocation('La geolocalización no es soportada por este navegador.');
    }
  };
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    // Radio de la Tierra en km
    const R = 6371;

    // Convertir grados a radianes
    const toRadians = (degree) => degree * (Math.PI / 180);

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distancia en kilómetros
    const distance = R * c;
    return distance;
  };


  const sortedRestaurants = restaurants
    .map(restaurant => {
      const distance = calculateDistance(
        latitude,
        longitude,
        parseFloat(restaurant.geometry.location.lat),
        parseFloat(restaurant.geometry.location.lng)
      ).toFixed(1);

      return {
        ...restaurant,
        distance: parseFloat(distance)
      };
    })
    .sort((a, b) => a.distance - b.distance); // Ordenar de menor a mayor distancia


  return (
    <Layout>



      <NavbarApp user={user}></NavbarApp>



      <div className="container text-center mb-5">
        {loading ?
          <div className="container px-auto text-center pt-5">

            <h1 className="mt-5">
              Estamos
              <Typewriter
                words={[' buscando los mejores lugares cerca a tu ubicación', ' organizando la información recolectada', ' dando el contexto a la IA']}
                loop={0} // 0 para bucle infinito
                cursor
                cursorStyle="_"
                typeSpeed={100}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </h1>

            <div className="d-flex justify-content-center mx-auto mt-5">
              <PacmanLoader color="#ff1423" size={50}


              />
            </div>
          </div>

          :
          !showRestaurants && ( // Solo muestra el texto si showRestaurants es false
            <div className="container my-5">
              <img src={eaztBrand} className="img-fluid"></img>
              <div className="text-center my-3">
                <h1 className="h1">¡Todo listo para comenzar!</h1>

                <button onClick={getLocation} class="cssbuttons-io-button mx-auto px-3 py-2 my-4">


                  Empezar

                </button>


                {location && (
                  <div className="mt-3">
                    <p>{location}</p>
                  </div>
                )}
              </div>
            </div>
          )

        }


        <div className="mb-5">

        </div>
        {showRestaurants && (
          <div className="container">
            {loading ? (
              <p className="text-center" style={{ fontSize: "25px" }}>Solo tardaremos unos segundos...</p>
            ) : (
              <>
                <h1 className="text-center my-5">Restaurantes cercanos</h1>
                {location && (
                  <div className="my-2">
                    <p>{location}</p>
                  </div>
                )}
              </>
            )}

            <div className="row">
              {sortedRestaurants.map((restaurant, index) => (
                <div className="col-md-4" key={index}>

                  <CardDate
                    name={restaurant.name}
                    types={restaurant.types}
                    photoUrl={restaurant.photo_url}
                    dir={restaurant.formatted_address}
                    reviews={restaurant.reviews}
                    rating={restaurant.rating}
                    distance={restaurant.distance.toFixed(1)} // Mostrar la distancia

                  />
                </div>
              ))}
            </div>

          </div>
        )}
      </div>
      <Footer></Footer>

    </Layout>
  )
}
const mapStateToProps = state => ({

  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user


})

export default connect(mapStateToProps, {
  login,
  refresh,
  check_authenticated,
  load_user


})(Restaurants)