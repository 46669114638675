import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import store from './store';
import Error404 from 'containers/errors/error404';
import Home from 'containers/pages/home';
import Restaurants from 'containers/pages/Restaurants';
import Favorites from 'containers/pages/Favorites';
import Login from 'containers/pages/Login';
import { Provider } from 'react-redux';
import Contact from 'containers/pages/Contact';
import About from 'containers/pages/About';
import Pricing from 'containers/pages/Pricing';
import ResetPassword from 'containers/pages/ResetPassword';
import ResetPasswordConfirm from 'containers/pages/ResetPasswordConfirm';
import Register from 'containers/pages/Register';
import Activate from 'containers/pages/Activate';
function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/*Error Display */}
          <Route path="*" element={<Error404 />} />

          {/*Home Display */}
          <Route path="/" element={<Home />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route exact path='/activate/:uid/:token' element={<Activate/>}/>
          <Route path="/restaurants" element={<Restaurants />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
