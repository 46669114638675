import React from 'react';
import jean from "assets/img/jean assoc.png";

const Associations = () => {
  return (
    <div className="container-flex-head ">


      <div className='container '>
        <h2 className='text-white text-center'> Nuestros Aliados</h2>

        <div className='row mt-5'>
          <div className='col-md-3'> 


          </div>
          <div className='col-md-3'> 
          <img className='img-fluid' src={jean}></img>

          </div>
          <div className='col-md-3'> 
          <img className='img-fluid' src={jean}></img>

          </div>
          <div className='col-md-3'> 


          </div>


        </div>
      </div>

    </div>
  );
};

export default Associations;
