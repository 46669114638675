
import Layout from "hocs/layouts/Layout"
import { connect } from "react-redux";
import React, { useState } from 'react';
import CardDate from "components/home/CardDate";
import NavbarApp from "components/navigation/NavbarApp";
import { useEffect } from 'react'
import { Navigate } from "react-router-dom";
import { check_authenticated, load_user, login, refresh } from "redux/actions/auth/auth";
import Footer from "components/navigation/Footer";
import { get_favorites } from "redux/actions/restaurants/restaurants";
function Favorites({
  login,
  isAuthenticated,
  refresh,
  check_authenticated,
  load_user,
  user,
  get_favorites,
  favorites

}) {
  useEffect(() => {
    window.scrollTo(0, 0)

    isAuthenticated ? <>
      {get_favorites()}


    </> :
      <>
        {refresh()}
        {check_authenticated()}
        {load_user()}


      </>
  }

    , [isAuthenticated])
  if (isAuthenticated == false) {
    return <Navigate to='/ingresar' />
  }








  return (
    <Layout>

      <NavbarApp user={user}></NavbarApp>

      <div className="container">
        <h1 className="h1-title text-center mt-4" >Tus Favoritos</h1>

        {favorites ? (

          <p className="mt-2 text-white text-justify">
            {favorites? (

              <div className="row">
                {favorites.results.favorites.map((restaurant, index) => (
                  <div className="col-md-4" key={index}>

                    <CardDate
                      name={restaurant.name}
                      photoUrl={restaurant.photo_url}
                      reviews={restaurant.reviews}
                      rating={restaurant.rating}

                    />
                  </div>
                ))}

              </div>




            ) : (
              <p className="text-white mt-5">Cargando opinión...</p>
            )}

          </p>




        ) : (
          <p className="text-white mt-5">Cargando Favoritos</p>
        )}

      </div>

      <Footer></Footer>

    </Layout>
  )
}
const mapStateToProps = state => ({

  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
  favorites: state.restaurants.favorites


})

export default connect(mapStateToProps, {
  login,
  refresh,
  check_authenticated,
  load_user,
  get_favorites


})(Favorites)