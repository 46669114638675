import { connect } from "react-redux"
import { useEffect, useState } from "react";
import { check_authenticated, load_user, login, refresh, reset_password } from "redux/actions/auth/auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout"

function ResetPassword({
  reset_password,
  isAuthenticated,
  loading,
  refresh,
  check_authenticated,
  load_user,
}) {

  useEffect(() => {
    isAuthenticated ? <></> :
      <>
        {refresh()}
        {check_authenticated()}
        {load_user()}
      </>
  }, [])

  const [formData, setFormData] = useState({
    email: ''
  });

  const {
    email
  } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const navigate = useNavigate()

  const onSubmit = e => {
    e.preventDefault();
    reset_password(email)
    navigate('/')
  }

  if (isAuthenticated) {
    return <Navigate to='/dashboard' />
  }


  return (


    <Layout>
      <Navbar />

      <div class="container-log mx-auto  my-5">
        <div class="login-form mx-auto my-5">
          <div class="header">
            <label class="title"> Recuperar</label>
            <label class="title mt-3"> Contraseña</label>
            <p class="description mt-5">Porfavor ingresa tu correo asociado a tu cuenta Eazt.</p>
          </div>
          <form onSubmit={e => { onSubmit(e) }} className="form_main mx-auto  " action="">
            <div class="input_container">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7 8.5L9.94202 10.2394C11.6572 11.2535 12.3428 11.2535 14.058 10.2394L17 8.5" stroke="#141B34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z" stroke="#141B34" stroke-width="1.5" stroke-linejoin="round"></path>
              </svg>
              <input placeholder="Email"
                id="email" name='email'
                className="input_field"
                type="email"
                required
                value={email}
                onChange={e => onChange(e)} />
            </div>
            <button class="sign-in_btn" type="submit" title="Sign In">
              <span>Cambiar contraseña</span>
            </button>

          </form>
        </div>

      </div>


    </Layout>


  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading
})

export default connect(mapStateToProps, {
  reset_password,
  refresh,
  check_authenticated,
  load_user,
})(ResetPassword)