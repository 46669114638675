
import Layout from "hocs/layouts/Layout"
import { connect } from "react-redux";
import Navbar from "components/navigation/Navbar";
import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import restaurant from "assets/img/dog_about.jpg";
import Footer from "components/navigation/Footer";

function About({

}) {

    const [formData, setFormData] = useState({
        fullName: '',
        reason: '',
        email: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        // Aquí puedes agregar la lógica para enviar los datos
    };





    return (
        <Layout>
            <Navbar />


            <div className="container-flex-head-2 ">
                <div className="container">
                <h1 className="text-white text-center mb-4">Sobre Nosotros</h1>
                    <div className="container  " style={{ backgroundColor: '#05060c', borderRadius: '10px' }}>
                        <div className="row ">


                            <div className="col-md-6">

                                <img src={restaurant} style={{ borderRadius: '10px' }} className="img-fluid" alt="" />

                            </div>
                            <div className="col-md-6 p-4">

                                <p className="text-white text-justify">

                                    Dos emprendedores apasionados por la gastronomía crearon Eazt con un objetivo claro:<br></br>

                                    Devolver la autenticidad al mundo de la comida. En un mercado donde el marketing y las redes sociales suelen distorsionar la realidad, Eazt se destaca como una plataforma 100% honesta, impulsada por inteligencia artificial. Nuestro enfoque está en destacar restaurantes que ofrezcan comida de calidad y evaluaciones verdaderas, sin la influencia de campañas pagadas. Queremos ayudar a los comensales a descubrir experiencias gastronómicas genuinas y apoyar a marcas que realmente merecen su reputación en el mercado.
                                </p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer className="mt-5"></Footer>



        </Layout>
    )
}
const mapStateToProps = state => ({






})

export default connect(mapStateToProps, {


})(About)